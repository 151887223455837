export default {
  // Endpoints
  loginEndpoint: '/auth/login',
  registerEndpoint: '/auth/register',
  refreshEndpoint: '/auth/refresh-tokens',
  logoutEndpoint: '/auth/logout',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  retrieveMfaMethods: '/auth/mfa-methods',
  createMfaMethod: '/auth/mfa-methods',
  disableMfaMethod: '/auth/mfa-methods/disable',
  enableMfaMethod: '/auth/mfa-methods/enable',
  deleteMfaMethod: '/auth/mfa-methods',
  requestMfaCode: '/auth/mfa-methods/send',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: '$accessToken',
  storageRefreshTokenKeyName: '$refreshToken',
};
